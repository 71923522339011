import { axios, API_ROUTES } from "@/config/index.js";

export default {
	async getAllBrandByClient(customer_id) {
		const url = API_ROUTES.brand.get.replace(':customer_id', customer_id)
		const { data } = await axios.get(url)

		return data
	},

	async getAllServicesByBrand(brand_id) {
		const url = API_ROUTES.service.get.replace(':brand_id', brand_id)

		const { data } = await axios.get(url)

		return data
	},

	async getAllAreaByService(service_id) {
		const params = {
			order: 'asc',
			order_by: 'name',
			offset: 0,
			limit: 200,
			'filters[0][field]': 'service_id',
			'filters[0][operator]': '=',
			'filters[0][value]': service_id,
		}
		const { data } = await axios.get(API_ROUTES.area.get,{
			params: params, 
		})

		return data
	},

	async getAllSubareaByArea(area_id) {
		const url = API_ROUTES.subarea.get.replace(':area_id', area_id)
		const { data } = await axios.get(url)

		return data
	},

	async getAllLocationPatient(area_id) {
		const url = API_ROUTES.locationPatient.get.replace(':area_id', area_id)
		const { data } = await axios.get(url)

		return data
	},

	async getAllByDate(idArea, date) {
		const params = {
			order: 'asc',
			order_by: 'date',
			offset: 0,
			limit: 200,
			'filters[0][field]': 'area_id',
			'filters[0][operator]': '=',
			'filters[0][value]': idArea,
			'filters[1][field]': 'date',
			'filters[1][operator]': '=',
			'filters[1][value]': date,
		}
		const { data } = await axios.get(API_ROUTES.order.get, {
			params: params,
		})

		return data
	},

	async getAllExamByAreaDate(idArea, date) {
		const params = {
			order: 'asc',
			order_by: 'date',
			offset: 0,
			limit: 200,
			'filters[0][field]': 'area_id',
			'filters[0][operator]': '=',
			'filters[0][value]': idArea,
			'filters[1][field]': 'ordered_date',
			'filters[1][operator]': '=',
			'filters[1][value]': date,
		}
		const { data } = await axios.get(API_ROUTES.examByDate.get, {
			params: params,
		})

		return data
	},

	altaInfo(id) {
		const url = API_ROUTES.altaInfo.update.replace(':id', id)
		return axios.put(url)
	},

	altaFisico(id) {
		const url = API_ROUTES.altaFisico.update.replace(':id', id)
		return axios.put(url)
	},
	find(id) {
		const url = API_ROUTES.locationPatient.find.replace(':id', id)
		return axios.get(url)
	},
}
